<template>
    <div>
        <h4 class="bold text-uppercase">Cài đặt trang chủ Landing Page</h4>
        <div class="row mt-4">
            <div class="col-md-8 col-12">
                <div class="form-group">
                    <label class="bold" for="">Nội dung html</label>
                    <textarea
                        class="form-control textarea-light"
                        rows="20"
                        v-model="html"
                        placeholder="Nhập nội dung html Landing Page"
                    ></textarea>
                </div>
                <div v-if="landing_page" class="form-group row">
                    <div class="col-md-6">
                        <button
                            @click="createLandingPage()"
                            class="mt-3 btn-block btn-lg btn font-bold btn-dark-blue"
                            type="submit"
                        >
                            Lưu Landing Page
                        </button>
                    </div>
                    <div class="col-md-6">
                        <button
                            @click="updateSiteInfo()"
                            class="mt-3 btn-block btn-lg btn font-bold btn-red"
                            type="submit"
                        >
                            Xóa Landing Page
                        </button>
                    </div>
                </div>
                <button
                    v-else
                    @click="createLandingPage()"
                    class="mt-3 btn-block btn-lg btn font-bold btn-dark-blue"
                    type="submit"
                >
                    Tạo Landing Page
                </button>
            </div>
            <div class="col-md-4 mt-3 mt-md-0 col-12">
                <div class="card card-info mt-3">
                    <div class="card-body">
                        <h6 class="font-bold text-center">VIDEO HƯỚNG DẪN</h6>
                        <iframe
                            class="mt-2"
                            width="100%"
                            height="300"
                            src="https://www.youtube.com/embed/Ox1BA_y5dNw"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                    </div>
                </div>
                <div class="card card-red h-100 mt-3">
                    <div class="card-body">
                        <h4>Lưu ý:</h4>
                        <h6>
                            - Trang landing page là trang sẽ xuất hiện mỗi khi vào trang của bạn để khách hàng tham khảo
                            về thông tin dịch vụ bạn cung cấp cũng như seo google
                        </h6>
                        <h6>- Bạn có thể tự do tùy biến html, css trang chủ của mình cho phù hợp với site</h6>
                        <h6>
                            - Trên trang landing page phải tạo button Login và trỏ về địa chỉ
                            <span class="bold">{{ host }}/login</span> để vào trang Login hiện tại.
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { createLandingPage, getInfoLandingPage } from "@/api/admin"
import { updateSiteInfo } from "../../../api/admin"
import { catchError } from "../../../helpers"
export default {
    name: "notification-setting",
    data() {
        return {
            html: ""
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.site.site
        },
        landing_page() {
            return this.$store.state.site.site.landing_page
        },
        host() {
            let siteHost = this.$store.state.site.site.domain
            return siteHost + ""
        }
    },
    async created() {
        this.getInfoLandingPage()
    },
    methods: {
        async getInfoLandingPage() {
            if (this.landing_page) {
                let postData = {
                    domain: this.host.replace("https", "http")
                }
                this.html = await getInfoLandingPage(postData)
            }
        },
        createLandingPage: async function() {
            let postData = {
                html: this.html
            }
            let data = await createLandingPage(postData)
            if (data.status === 200 && data.success) {
                this.$swal("Thành Công", data.message, "success")
                await this.$store.dispatch("GET_SITE", this.user.site_id)
            } else this.$swal("Lỗi", data.message, "error")
        },
        updateSiteInfo: async function() {
            let siteUpdate = Object.assign({}, this.site)
            siteUpdate.landing_page = null
            let data = await updateSiteInfo(siteUpdate)
            if (data.status === 200 && data.success) {
                this.$swal("Thành Công", data.message, "success")
                this.html = ""
                await this.$store.dispatch("GET_SITE", this.user.site_id)
            } else this.$swal("Lỗi", catchError(data), "error")
        }
    }
}
</script>
